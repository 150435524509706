import React from 'react';
import { IconSVGProps } from 'types/icon';

const IconCalendar: React.FC<IconSVGProps> = ({
  height = 16,
  width = 16,
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.5 3a.875.875 0 00-.875.875v.875H4.75A1.75 1.75 0 003 6.5v8.75c0 .966.784 1.75 1.75 1.75h10.5A1.75 1.75 0 0017 15.25V6.5a1.75 1.75 0 00-1.75-1.75h-.875v-.875a.875.875 0 00-1.75 0v.875h-5.25v-.875A.875.875 0 006.5 3zm0 4.375a.875.875 0 100 1.75h7a.875.875 0 000-1.75h-7z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default IconCalendar;
