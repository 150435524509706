import classNames from 'classnames';
import { PreventAutocompleteAttributes } from 'components/PreventAutocompleteAttributes';
import React from 'react';
import { PropsOf } from './utils';

const defaultElement = 'input';

export interface InputProps extends PropsOf<typeof defaultElement> {
  name?: string;
}

function InputComponent(
  { className, name, type = 'text', ...props }: InputProps,
  ref: React.Ref<HTMLInputElement>
) {
  return (
    <input
      type={type}
      name={name}
      className={classNames('flex', className)}
      ref={ref}
      {...PreventAutocompleteAttributes}
      {...props}
    />
  );
}

const Input = React.memo(React.forwardRef(InputComponent));

export default Input;
