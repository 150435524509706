import classNames from 'classnames';
import React from 'react';
import { ColorFamily } from '@graphql-types@';
import { EVENT_COLOR_MAP } from 'utils/eventColors';

interface PropsLoadingSpinner {
  size: number;
  isLoading?: boolean;
  ring?: ColorFamily;
  className?: string;
}

interface PropsSpinnerPart {
  ring?: ColorFamily;
  size: number;
  delay: string;
}

function SpinnerPart({ ring, size, delay }: PropsSpinnerPart) {
  return (
    <div
      className={classNames([
        'absolute box-border block animate-spin animate-spinBezier rounded-full border-2 border-solid',
        {
          [ring ? EVENT_COLOR_MAP[ring].border : '']: ring,
        },
      ])}
      style={{
        width: size,
        height: size,
        animationDelay: delay,
        borderRightColor: 'transparent',
        borderLeftColor: 'transparent',
        borderBottomColor: 'transparent',
      }}
    />
  );
}

export default function LoadingSpinner({
  size,
  isLoading,
  ring,
  className,
}: PropsLoadingSpinner) {
  return (
    <div
      style={{ width: size, height: size }}
      className={classNames([
        {
          hidden: !isLoading,
        },
        className,
      ])}
    >
      <SpinnerPart size={size} ring={ring} delay="-0.45s" />
      <SpinnerPart size={size} ring={ring} delay="-0.3s" />
      <SpinnerPart size={size} ring={ring} delay="-0.15s" />
    </div>
  );
}
