import React from 'react';
import { IconSVGProps } from 'types/icon';
import { TEST_ID_ICON_MAP_PIN } from 'utils/constants';

const IconMapPin: React.FC<IconSVGProps> = ({
  height = 12,
  width = 10,
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 10 12"
      data-testid={TEST_ID_ICON_MAP_PIN}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1.465 1.456A5.016 5.016 0 015 0c1.326 0 2.598.524 3.535 1.456a4.956 4.956 0 011.465 3.515 4.956 4.956 0 01-1.465 3.514L5.353 11.65a.5.5 0 01-.705 0L1.465 8.485A4.968 4.968 0 010 4.971a4.945 4.945 0 011.465-3.515zM5 6.39c.378 0 .742-.15 1.01-.416a1.416 1.416 0 000-2.009 1.433 1.433 0 00-2.02 0 1.416 1.416 0 000 2.009c.267.266.63.416 1.01.416z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default IconMapPin;
