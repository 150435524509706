import { mergeProps } from '@react-aria/utils';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useMemo } from 'react';
import type { Placement } from 'react-laag';
import { Arrow, mergeRefs, useHover, useLayer } from 'react-laag';
import ResizeObserver from 'resize-observer-polyfill';
import { isReactText } from './utils';

export interface TooltipOwnProps {
  children: React.ReactChild;
  content: React.ReactChild;
  shortcut?: string;
  customContainerId?: string;
  disabled?: boolean;
  placement?: Placement;
  targetOffset?: number;
}

function TooltipComponent(
  {
    children,
    content,
    shortcut,
    disabled = false,
    placement = 'bottom-center',
    customContainerId,
    targetOffset,
    ...props
  }: TooltipOwnProps,
  ref: React.Ref<HTMLElement>
) {
  const [isOver, { onMouseLeave, onMouseEnter, ...hoverPropsTouch }, close] =
    useHover({
      delayEnter: 100,
      delayLeave: 100,
    });

  // https://github.com/facebook/react/issues/18753#issuecomment-630771296
  const hoverProps = useMemo(
    () => ({
      ...hoverPropsTouch,
      onPointerEnter: onMouseEnter,
      onPointerLeave: onMouseLeave,
    }),
    [hoverPropsTouch, onMouseEnter, onMouseLeave]
  );

  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen: isOver,
    placement,
    onParentClose: close,
    possiblePlacements: ['top-center', 'bottom-center', placement],
    auto: true,
    triggerOffset: targetOffset || 8,
    ResizeObserver,
    container: customContainerId,
  });

  const tooltipProps = useMemo(
    () => ({
      onMouseDown: close,
    }),
    [close]
  );

  if (disabled) {
    return <>{children}</>;
  }

  let trigger;

  if (isReactText(children)) {
    trigger = (
      <span
        {...mergeProps(triggerProps, hoverProps, tooltipProps)}
        ref={mergeRefs(triggerProps.ref, ref)}
      >
        {children}
      </span>
    );
  } else {
    trigger = React.cloneElement(children, {
      ...mergeProps(
        children.props,
        triggerProps,
        hoverProps,
        tooltipProps,
        props
      ),
      ref: mergeRefs(
        triggerProps.ref,
        ref,
        (children as typeof children & React.ClassAttributes<typeof children>)
          .ref
      ),
    });
  }

  return (
    <>
      {trigger}
      {renderLayer(
        <AnimatePresence>
          {isOver && (
            <motion.div
              key="tooltip"
              className="pointer-events-none z-100 flex rounded-lg bg-black p-2 text-gray-100"
              initial={{ opacity: 0, scale: 0.94 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.94 }}
              transition={{ duration: 0.064 }}
              {...mergeProps(layerProps, hoverProps)}
            >
              {isReactText(content) ? (
                <span className="text-xs font-semibold leading-none">
                  {content}
                </span>
              ) : (
                content
              )}

              {shortcut && (
                <span className="ss02 ml-1.5 -mt-1 -mb-1 -mr-1 flex h-5 min-w-[20px] items-center justify-center rounded-md bg-gray-750 p-1 text-[11px] font-semibold leading-none tracking-wider text-gray-300">
                  {shortcut}
                </span>
              )}

              <Arrow
                {...arrowProps}
                backgroundColor="black"
                roundness={1.5}
                size={4}
              />
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </>
  );
}

const Tooltip = React.forwardRef(TooltipComponent);

export default Tooltip;
