import classNames from 'classnames';
import React from 'react';
import Tooltip from './Tooltip';
import { PropsOf } from './utils';

const defaultElement = 'button';

type Variant =
  | 'floating'
  | 'floatingShadowless'
  | 'popoverIconSmall'
  | 'popoverIcon'
  | 'popoverIconLarge'
  | 'popoverIconLargeFullyRounded'
  | 'base';

type ButtonColor = 'blue' | 'red' | 'default';

export interface ButtonOwnProps<
  E extends React.ElementType = React.ElementType
> {
  as?: E;
  activeScale?: boolean;
  variant?: Variant;
  color?: ButtonColor;
  loading?: boolean;
  ariaLabel?: string;
  disabled?: boolean;
  tooltipEnabled?: boolean;
  tooltipProps?: Pick<PropsOf<typeof Tooltip>, 'placement'>;
}

export type ButtonProps<E extends React.ElementType> = ButtonOwnProps<E> &
  Omit<PropsOf<E>, keyof ButtonOwnProps>;

const floatingBaseClassnames =
  'flex w-max items-center font-semibold text-sm p-2.5 dark:text-gray-300 active:scale-[0.98]';

const sharedBackgroundBehavior =
  'bg-opacity-0 bg-black hover:bg-opacity-5 focus-visible:bg-opacity-5 dark:hover:bg-opacity-10 dark:focus-visible:bg-opacity-10';

const baseVariantColors: Record<ButtonColor, string> = {
  blue: `${floatingBaseClassnames} h-8 rounded-lg bg-blue-50 dark:bg-blue-400 dark:bg-opacity-10 hover:bg-blue-100 dark:hover:bg-opacity-20 dark:text-blue-400 text-blue-500`,
  red: `${floatingBaseClassnames} h-8 rounded-lg bg-red-50 dark:bg-red-400 dark:bg-opacity-10 hover:bg-red-100 dark:hover:bg-opacity-20 dark:text-red-400 text-red-500`,
  default: `${floatingBaseClassnames} h-8 rounded-lg`,
};

function ButtonComponent(
  {
    as,
    variant,
    activeScale = true,
    color = 'default',
    className,
    ariaLabel,
    disabled = false,
    ...props
  }: ButtonProps<typeof defaultElement>,
  ref: React.Ref<HTMLButtonElement>
) {
  const Component = as || defaultElement;

  const isDefaultElement = Component === defaultElement;

  const buttonComponent = (
    <Component
      aria-label={ariaLabel}
      ref={ref}
      type={isDefaultElement && !props?.type ? 'button' : props?.type}
      disabled={disabled}
      className={classNames(
        'appearance-none',
        {
          'scale-100 transition-all active:scale-[0.98]':
            isDefaultElement && activeScale,
          [`${floatingBaseClassnames} h-11 rounded-xl bg-white shadow-md dark:bg-gray-700`]:
            variant === 'floating',
          [sharedBackgroundBehavior]:
            variant !== undefined &&
            [
              'floatingShadowless',
              'popoverIcon',
              'popoverIconLarge',
              'popoverIconLargeFullyRounded',
              'popoverIconSmall',
            ].includes(variant) &&
            !disabled,
          [`${floatingBaseClassnames} flex h-8 rounded-lg`]:
            variant === 'floatingShadowless',
          [`flex h-4 w-4 items-center justify-center rounded-md text-sm font-semibold`]:
            variant === 'popoverIconSmall',
          [`text-secondary flex h-6 w-6 items-center justify-center rounded-md p-1 text-sm font-semibold`]:
            variant === 'popoverIcon',
          [`text-secondary flex h-7 w-7 items-center justify-center rounded-md p-1 text-sm font-semibold`]:
            variant === 'popoverIconLarge',
          [`text-secondary flex h-9 w-9 items-center justify-center rounded-full p-1 text-sm font-semibold`]:
            variant === 'popoverIconLargeFullyRounded',
          ['active:scale-[0.95]']:
            (variant === 'popoverIcon' ||
              variant === 'popoverIconSmall' ||
              variant === 'popoverIconLarge' ||
              variant === 'popoverIconLargeFullyRounded') &&
            !disabled,
          [baseVariantColors[color]]: variant === 'base',
        },
        className
      )}
      {...props}
    />
  );

  return buttonComponent;
}

const Button = React.forwardRef(ButtonComponent) as <
  E extends React.ElementType = typeof defaultElement
>(
  props: ButtonProps<E>
) => JSX.Element;

export default Button;
