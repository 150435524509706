import classNames from 'classnames';
import React from 'react';
import { TEST_ID_GUEST_AVATAR } from 'utils/constants';
import { AvatarStatus } from './Avatar';

interface Props {
  size: number;
  status: AvatarStatus;
  alternate?: boolean;
  className?: string;
  alternateClassName?: string;
}

export default function AvatarStatusIcon({
  size,
  alternate = false,
  status,
  className = 'border-gray-100 dark:border-gray-600 border-2',
  alternateClassName = 'bg-red-50 dark:bg-gray-900',
}: Props) {
  return (
    <span
      data-testid={TEST_ID_GUEST_AVATAR}
      className={classNames(
        'relative flex shrink-0 items-center justify-center rounded-full',
        className,
        {
          'bg-green-500': status === 'green',
          'bg-red-500': status === 'red',
          'bg-yellow-500': status === 'orange',
          'bg-gray-400': status === 'gray',
        }
      )}
      style={{
        height: Math.max(size * 0.44, className === undefined ? 12 : 10),
        width: Math.max(size * 0.44, className === undefined ? 12 : 10),
      }}
    >
      {alternate && status === 'red' && (
        <span
          className={classNames(
            'absolute flex h-0.5 rounded-sm',
            alternateClassName
          )}
          style={{
            width: 'calc(100% - 4px)',
          }}
        />
      )}
      {alternate && status === 'gray' && (
        <span
          className={classNames(
            'absolute flex rounded-full',
            alternateClassName
          )}
          style={{
            width: 'calc(100% - 6px)',
            height: 'calc(100% - 6px)',
          }}
        />
      )}
    </span>
  );
}
