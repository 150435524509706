import { mergeProps, useId, useUpdateEffect } from '@react-aria/utils';
import classNames from 'classnames';
import { PreventAutocompleteAttributes } from 'components/PreventAutocompleteAttributes';
import { useHotkeyContext } from 'contexts/hotkey';
import { AnimatePresence, motion } from 'framer-motion';
import useHotkey from 'hooks/useHotkey';
import { isEmpty } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { mergeRefs, useLayer } from 'react-laag';
import ResizeObserver from 'resize-observer-polyfill';
import Avatar from './Avatar';
import { cleanComboBoxItems, PropsOf } from './utils';

interface ComboBoxItemSeparator {
  type: 'separator';
}

interface ComboBoxItemTitle {
  type: 'title';
  value: string;
}

interface AvatarGroupItem {
  url: string;
  text: string;
}

export interface ComboBoxItemOption {
  type: 'option';
  value: string;
  text?: string;
  subtext?: string;
  avatar?: string | null;
  avatarGroup?: AvatarGroupItem[];
  disabled?: boolean;
  onSelect?: (actions: ComboBoxActions) => void;
  icon?: React.ReactElement;
}

export type ComboBoxItem =
  | ComboBoxItemOption
  | ComboBoxItemTitle
  | ComboBoxItemSeparator;

export interface ComboBoxActions {
  setInputValue: (value: string) => void;
  setInputFocused: (value: boolean) => void;
  setOpen: (value: boolean) => void;
  setHighlightedIndex: (value: number, scrollIntoView: boolean) => void;
}

export interface ComboBoxProps {
  items: ComboBoxItem[] | (() => ComboBoxItem[]);
  className?: string;
  menuClassName?: string;
  menuWidth?: number;
  inputRef?: React.MutableRefObject<
    HTMLInputElement | HTMLTextAreaElement | null
  >;
  offset?: number;
  submitOnBlur?: boolean;
  hideWhenEmpty?: boolean;
  children: React.ReactElement;
  onInputSubmit?: () => void;
  onInputChange?: (inputValue: string) => void;
  onInputFocusChange?: (value: boolean) => void;
  onHighlightChange?: (index: number) => void;
  onSubmit: (
    inputValue: string,
    item: ComboBoxItemOption | undefined,
    actions: ComboBoxActions
  ) => void;
}

function ComboBoxComponent(
  {
    className,
    menuWidth,
    menuClassName,
    inputRef,
    offset = 4,
    onInputSubmit,
    onInputChange,
    onInputFocusChange,
    onSubmit,
    submitOnBlur = false,
    hideWhenEmpty = false,
    items: unfilteredItems,
    children,
    onHighlightChange,
  }: ComboBoxProps,
  ref: React.Ref<HTMLDivElement>
) {
  const childrenProps: PropsOf<'input'> | undefined = children.props;
  const autoCompleteId = useId();
  const [open, setOpen] = useState(true);
  const optionElementRefs = useRef<Array<HTMLLIElement | null>>([]);
  const { setScope, setPreviousScope } = useHotkeyContext();
  const [highlightedIndex, _setHighlightedIndex] = useState(-1);
  const [inputFocused, _setInputFocused] = useState(false);
  const [inputValue, _setInputValue] = useState(
    childrenProps?.value?.toString() || ''
  );

  const items = useMemo(
    () =>
      inputFocused
        ? cleanComboBoxItems(
            typeof unfilteredItems === 'function'
              ? unfilteredItems()
              : unfilteredItems
          )
        : [],
    [inputFocused, unfilteredItems]
  );

  useEffect(() => {
    onHighlightChange?.(highlightedIndex);
  }, [highlightedIndex, onHighlightChange]);

  const forceHide =
    hideWhenEmpty && inputValue.trim().length === 0 && highlightedIndex < 0;
  const showMenu = open && items.length > 0 && inputFocused && !forceHide;

  const { renderLayer, triggerProps, layerProps, triggerBounds, layerSide } =
    useLayer({
      isOpen: showMenu,
      auto: true,
      snap: true,
      placement: 'bottom-start',
      possiblePlacements: ['top-start', 'bottom-start'],
      triggerOffset: offset,
      containerOffset: 0,
      ResizeObserver,
    });

  const setInputValue = useCallback(
    (value: string) => {
      _setInputValue(value);
      setOpen(true);
      onInputChange?.(value);
    },
    [onInputChange]
  );

  const findItemOptionIndex = useCallback(
    (needle: string) => {
      // Check the value first, because it will be without the duration
      // decorator. E.g. An end time's item.value could be "4:00 PM"
      // while its item.text may be "4:00 PM (1h 30m)"
      // which will cause the search to fail.
      return items.findIndex(
        (item) => item.type === 'option' && (item.value || item.text) === needle
      );
    },
    [items]
  );

  const setHighlightedIndex = useCallback(
    (value: number, scrollIntoView = false) => {
      _setHighlightedIndex(value);

      if (!scrollIntoView) return;

      const selectedElementRef = optionElementRefs.current?.[value];

      // Ensure that scrollIntoView exists to make Cypress happy.
      if (selectedElementRef?.scrollIntoView)
        selectedElementRef.scrollIntoView({
          block: 'nearest',
          inline: 'nearest',
        });
    },
    [optionElementRefs]
  );

  const setInputFocused = useCallback(
    (value: boolean) => {
      _setInputFocused(value);
      onInputFocusChange?.(value);

      if (value) {
        inputRef?.current?.focus();
      } else {
        setHighlightedIndex(-1);
        inputRef?.current?.blur();
      }
    },
    [inputRef, onInputFocusChange, setHighlightedIndex]
  );

  const actions: ComboBoxActions = useMemo(() => {
    return {
      setOpen,
      setInputValue,
      setInputFocused,
      setHighlightedIndex,
    };
  }, [setHighlightedIndex, setInputFocused, setInputValue]);

  const submitItem = useCallback(
    (item?: ComboBoxItemOption | undefined) => {
      const highlightedItem: ComboBoxItem | undefined =
        item || items[highlightedIndex];
      const highlightedOption =
        highlightedItem?.type === 'option' ? highlightedItem : undefined;

      // Menu isn't open, or no option is selected
      // hence we can assume that the user is trying to submit the input
      if (!showMenu || !highlightedOption) {
        onInputSubmit?.();
        return;
      }

      if (highlightedIndex >= 0 && highlightedOption?.value) {
        setInputValue(highlightedOption.value);
      }

      setHighlightedIndex(-1);

      onSubmit(
        highlightedOption?.value || inputValue,
        highlightedOption,
        actions
      );
      item?.onSelect?.(actions);
    },
    [
      showMenu,
      onInputSubmit,
      actions,
      highlightedIndex,
      inputValue,
      items,
      onSubmit,
      setHighlightedIndex,
      setInputValue,
    ]
  );

  const getInputProps = useCallback(
    (childrenProps: PropsOf<'input'>) => {
      const props: PropsOf<'input'> = {
        name: autoCompleteId,
      };

      props.onChange = (event) => {
        // If the user starts typing, then remove any highlight we may have.
        setHighlightedIndex(-1);

        setInputValue(event.currentTarget.value);
      };

      props.onBlur = () => {
        setInputFocused(false);
        if (submitOnBlur) {
          submitItem();
        }
      };

      props.onFocus = () => {
        setOpen(true);
        setInputFocused(true);
      };

      return mergeProps(props, childrenProps);
    },
    [
      autoCompleteId,
      setInputFocused,
      setHighlightedIndex,
      setInputValue,
      submitItem,
      submitOnBlur,
    ]
  );

  const getItemProps = useCallback(
    ({
      disabled,
      index,
      item,
    }: {
      disabled: ComboBoxItemOption['disabled'];
      index: number;
      item: ComboBoxItem;
    }) => {
      const props: PropsOf<'li'> = {
        onMouseDown: (event) => event.preventDefault(),
      };

      if (item.type !== 'option' || disabled) return props;

      props.onMouseEnter = () => setHighlightedIndex(index);
      props.onMouseLeave = () => setHighlightedIndex(-1);
      props.onClick = (e) => (e.stopPropagation(), submitItem(item));
      props.ref = (elem) => (optionElementRefs.current[index] = elem);

      return props;
    },
    [optionElementRefs, setHighlightedIndex, submitItem]
  );

  const moveHighlightedIndex = useCallback(
    (direction: 'up' | 'down', scrollIntoView = false) => {
      const navigableItems = items.filter(
        (item) => item.type === 'option' && !item.disabled
      );

      const relativeIndex =
        highlightedIndex === -1
          ? highlightedIndex
          : navigableItems.indexOf(items[Math.max(highlightedIndex, 0)]);

      const nextRelativeIndex =
        direction === 'up' ? relativeIndex - 1 : relativeIndex + 1;

      const relativeIndexMin = Math.max(nextRelativeIndex, 0);
      const allowedRelativeIndex = Math.min(
        relativeIndexMin,
        navigableItems.length - 1
      );

      const nextIndex = items.indexOf(navigableItems[allowedRelativeIndex]);

      setHighlightedIndex(nextIndex, scrollIntoView);
    },
    [highlightedIndex, items, setHighlightedIndex]
  );

  // Automatically highlight the option which matches the input's value on menu open,
  // or the first item in the list if there is no match.
  useUpdateEffect(() => {
    if (isEmpty(inputValue)) return;
    setHighlightedIndex(findItemOptionIndex(inputValue), true);
  }, [showMenu]);

  useEffect(() => {
    if (inputFocused) {
      setScope('comboBox');
    }

    return () => {
      if (!inputFocused) {
        setPreviousScope();
      }
    };
  }, [inputFocused, setScope, setPreviousScope]);

  useUpdateEffect(() => {
    if (childrenProps?.value === undefined) return;
    _setInputValue(childrenProps.value.toString());
  }, [childrenProps?.value]);

  useHotkey(
    'escape',
    {
      enabled: inputFocused,
      enabledWithinInput: true,
      scope: 'comboBox',
    },
    () => {
      if (
        childrenProps?.defaultValue &&
        inputValue !== childrenProps?.defaultValue &&
        inputValue !== ''
      ) {
        setInputValue(childrenProps?.defaultValue?.toString());
      }

      if (inputRef?.current) inputRef.current.blur();
      setInputFocused(false);
    }
  );

  useHotkey(
    'enter',
    {
      enabled: inputFocused,
      enabledWithinInput: true,
      scope: 'comboBox',
    },
    () => submitItem()
  );

  useHotkey(
    'up, down',
    {
      enabled: inputFocused,
      enabledWithinInput: true,
      scope: 'comboBox',
    },
    (event, hotkey) => {
      if (hotkey.key !== 'up' && hotkey.key !== 'down') return;

      event.preventDefault();
      event.stopPropagation();

      moveHighlightedIndex(hotkey.key, true);
      setOpen(true);
    }
  );

  const renderedMenuWidth = useMemo(() => {
    if (menuWidth === undefined) return triggerBounds?.width;

    // 0 or negative indicates that we should not restrict the menu's width.
    if (menuWidth <= 0) return undefined;

    return menuWidth;
  }, [menuWidth, triggerBounds?.width]);

  return (
    <div ref={ref} className={className}>
      {React.cloneElement(children, {
        ...getInputProps({
          ...PreventAutocompleteAttributes,
          ...children.props,
          ...triggerProps,

          // TODO: Support aria-controls for the combobox.
          'aria-controls': '',

          role: 'combobox',
          'aria-expanded': showMenu,
        }),
        ref: mergeRefs(triggerProps.ref, inputRef),
        value: inputValue,
      })}

      {renderLayer(
        <AnimatePresence>
          {showMenu && (
            <motion.ul
              {...layerProps}
              className={classNames(
                'bg-dropdown text-primary z-100 max-h-40 w-auto overflow-y-auto rounded-lg text-s font-medium shadow-feintXl',
                menuClassName
              )}
              style={{
                width: renderedMenuWidth,
                ...layerProps.style,
              }}
              initial={{
                opacity: 0,
                scale: 0.99,
                y: layerSide === 'top' ? 8 : -8,
              }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{
                opacity: 0,
                scale: 0.99,
                y: layerSide === 'top' ? 8 : -8,
              }}
              transition={{
                duration: 0.08,
              }}
              role="listbox"
            >
              {items.map((item, index) => {
                if (item.type === 'separator') {
                  return (
                    <li
                      key={index + item.type}
                      className="mx-3 -mt-1 mb-2.5 flex pt-1.5"
                      {...getItemProps({
                        disabled: true,
                        item,
                        index,
                      })}
                    >
                      <span className="flex h-px w-full bg-gray-100 dark:bg-gray-800" />
                    </li>
                  );
                }

                if (item.type === 'title') {
                  return (
                    <li
                      key={item.value + item.type}
                      className="flex px-3.5 pt-3 pb-2"
                      {...getItemProps({
                        disabled: true,
                        item,
                        index,
                      })}
                    >
                      <p className="text-[11px] font-black uppercase leading-snug tracking-wider text-gray-400">
                        {item.value}
                      </p>
                    </li>
                  );
                }

                return (
                  <li
                    key={index + item.type + item.value}
                    className="group -mt-2 flex w-full cursor-pointer items-center p-1 first:-mt-0"
                    {...getItemProps({
                      item,
                      index,
                      disabled: item.disabled,
                    })}
                    aria-selected={index === highlightedIndex}
                    role="option"
                  >
                    <button
                      className={classNames(
                        'flex w-full items-center truncate rounded-md px-2.5 py-2',
                        {
                          'bg-gray-100 dark:bg-gray-700':
                            index === highlightedIndex,
                          'justify-between': !!item.avatarGroup?.length,
                        }
                      )}
                    >
                      {item.icon !== undefined && item.icon}

                      {item.avatar !== undefined && (
                        <span className="mr-2">
                          <Avatar
                            size={16}
                            src={item.avatar || undefined}
                            name={item.text || item.value}
                          />
                        </span>
                      )}

                      <div className="flex items-baseline space-x-1 truncate">
                        <p className="truncate">{item.text || item.value}</p>
                        {item.subtext && (
                          <small className="text-secondary text-xs">
                            {item.subtext}
                          </small>
                        )}
                      </div>

                      {item.avatarGroup !== undefined && (
                        <div className="relative z-0 ml-2 flex -space-x-1 ">
                          {item.avatarGroup.slice(0, 3).map((avatar, index) => (
                            <Avatar
                              key={`group-${avatar.text}`}
                              src={avatar.url}
                              name={avatar.text}
                              size={16}
                              className={classNames(
                                'inline-block rounded-full ring-2 ring-white group-hover:ring-gray-100 dark:ring-gray-900 dark:group-hover:ring-gray-800',
                                {
                                  'z-0': index === 0,
                                  'z-10': index === 1,
                                  'z-20': index === 2,
                                }
                              )}
                            />
                          ))}
                          {item.avatarGroup.length > 3 && (
                            <span
                              style={{ fontSize: 8 }}
                              className="bg-popover z-30 flex h-4 w-4 items-center justify-center rounded-full bg-gray-150 ring-2 ring-white group-hover:ring-gray-100 dark:ring-gray-900 dark:group-hover:ring-gray-800"
                            >{`+${item.avatarGroup.length - 3}`}</span>
                          )}
                        </div>
                      )}
                    </button>
                  </li>
                );
              })}
            </motion.ul>
          )}
        </AnimatePresence>
      )}
    </div>
  );
}

const ComboBox = React.memo(React.forwardRef(ComboBoxComponent));

export default ComboBox;
