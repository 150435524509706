import React from 'react';
import { IconSVGProps } from 'types/icon';

export const IconExclamationMark: React.FC<IconSVGProps> = ({
  height = 16,
  width = 16,
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 12 12"
      fill="currentColor"
      aria-label="All guests have declined"
    >
      <path
        d="M6 11.8154C9.12061 11.8154 11.688 9.24805 11.688 6.12744C11.688 3.00146 9.12061 0.434082 5.99463 0.434082C2.86865 0.434082 0.306641 3.00146 0.306641 6.12744C0.306641 9.24805 2.87402 11.8154 6 11.8154ZM6 6.90088C5.61328 6.90088 5.3877 6.68604 5.36621 6.29932L5.2749 4.04883C5.25879 3.62988 5.5542 3.35059 5.99463 3.35059C6.42969 3.35059 6.73047 3.63525 6.70898 4.04883L6.62305 6.29395C6.60156 6.69141 6.38135 6.90088 6 6.90088ZM6 8.85596C5.56494 8.85596 5.22119 8.58203 5.22119 8.16309C5.22119 7.74951 5.55957 7.47559 6 7.47559C6.44043 7.47559 6.76807 7.74951 6.76807 8.16309C6.76807 8.5874 6.43506 8.85596 6 8.85596Z"
        fill="currentColor"
      />
    </svg>
  );
};
